/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/material.theme.css";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.toast-container .ngx-toastr {
  border-radius: 5px;
  color: #fff;
  overflow: hidden;
  z-index: 5000;
  min-width: 400px;
  max-width: 400px;
  background-size: 0;
  padding: 0;
  box-shadow: none;
}

.toast-title {
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  font-weight: bold;
}

.toast-message {
  background: rgba(0, 0, 0, 0.4);
  padding: 10px;
  max-height: 200px;
}

.toast-success {
  border-left: rgba(27, 158, 119) 6px solid;
  background-color: rgba(27, 158, 119, 0.7);
  background-image: none;
}

.toast-info {
  border-left: rgba(189, 189, 189) 6px solid;
  background-color: rgba(189, 189, 189, 0.7);
  background-image: none;
}

.toast-warning {
  border-left: rgb(255, 214, 0) 6px solid;
  background-color: rgba(255, 214, 0, 0.7);
  background-image: none;
}

.toast-error {
  border-left: rgb(255, 0, 0) 6px solid;
  background-color: rgba(255, 0, 0, 0.7);
  background-image: none;
}

button.toast-close-button {
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: #fff;
  border: 0;
  margin-right: 15px;
  border-radius: 100%;
  margin-top: 12px;
  display: flex;
  color: black;
  justify-content: center;
  flex-direction: row;
  align-items: flex-end;
}
